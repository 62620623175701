<template>
  <div class="product-lines">
    <div class="page-banner">
      <div
        class="container"
        style="background-image:url('https://downloads.acs.com.hk/product-line-banner/pclinked_banner.jpg');"
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a class="title" title="ERP System">ERP System</a>
            </h1>
            <!-- <p class="caption">
              ACS develops and provides high-quality and reliable PC-linked
              smart card readers, based on various industry standards such as
              PC/SC (personal Computer/ Smart Card) and EMV (Europay, MasterCard
              and Visa).
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container product-line-info">
      <div
        class="col-md-10 offset-md-1"
        v-for="submenu in product.submenu"
        :key="submenu.title"
      >
        <h4>{{ submenu.title }}</h4>
        <div class="row">
          <div
            v-for="subproduct in submenu.submenu"
            :key="subproduct.title"
            class="col-sm-4 product-line product"
          >
            <b-link href="#">
              <img
                class="img-responsive"
                :src="subproduct.img"
                alt="ACR39U Smart Card Reader"
            /></b-link>
            <h2>
              <b-link href="#">{{ subproduct.title }}</b-link>
            </h2>
            <!-- <p>Next-generation contact card reader with USB Type A connector</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import products from "@/assets/json/product.json";
export default {
  name: "ProductCategory",
  data() {
    return {
      product: {}
    };
  },
  mounted() {
    var self = this;
    self.product = self._.find(products, { title: self.$route.params.id });
  }
};
</script>
